import { m } from 'framer-motion';
import PropTypes from 'prop-types';

// @mui
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import { bgBlur } from 'src/theme/css';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField, Button, Grid, Typography, Box, Modal, Step, Stepper, StepLabel, InputAdornment, FormControlLabel, Checkbox, CircularProgress, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useSnackbar } from 'src/components/snackbar'
import axios, { endpoints } from 'src/utils/axios';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { paths } from 'src/routes/paths';
import SellGiftcardsStepper from 'src/pages/dashboard/sellGiftCardStepper';
import { restoreTokenFromStorage } from 'src/auth/context/jwt/utils';
import { varHover } from 'src/components/animate';
import { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hook';
import { HEADER, NAV } from '../config-layout';
import {
  AccountPopover,
  SettingsButton,
  ContactsPopover,
} from '../_common';

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
  overflow: 'auto',
  borderRadius: "10px",
  paddingBottom: 0
};

// Media query for smaller viewports
const smallViewportStyle = {
  width: '95%',
  maxWidth: '100%',
};

// Adjust style based on viewport width
const responsiveStyle = window.innerWidth <= 600 ? style : style;

const steps = ['Customer Details', 'Billing Details', 'Enter OTP', 'Acknowledgement', 'Finish'];

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const popover = usePopover();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const staff = localStorage.getItem('isStaff') === 'true';
  const router = useRouter();
  const handleChatPageClick = () => {
    if (!staff) {
      router.push(paths.dashboard.chat);
    } else {
      router.push('/dashboard-s/chat');
      // Redirect to '/login';
      // window.location.href = '/dashboard-s/chat';
    }
    // Navigate to the chat page
    setNewMessage(false);
  };

  // For customer
  // Get All HOOKS
  const { enqueueSnackbar } = useSnackbar();



  // For Reward Modal
  const [rewardModal, setRewardModal] = useState(false);
  const [newMessage, setNewMessage] = useState(false);

  // For modal
  const [openSell, setOpenSell] = useState(false);
  const handleClose = () => setOpenSell(false);
  const [finishButtonLoading, setFinishButtonLoading] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [backButtonLoading, setBackButtonLoading] = useState(false);

  // **************************************************************************************
  // For Refund Modal
  const [refundModal, setRefundModal] = useState(false);
  const [refundConfirmation, setRefundConfirmation] = useState(false);
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const [refundCustomerId, setRefundCustomerId] = useState('');
  const [showRefundFields, setShowRefundFields] = useState(false);
  const [refundErr, setRefundErr] = useState({
    msg: '',
    err: false
  });
  const [refundCustomerData, setRefundCustomerData] = useState({});
  const [refundAmount, setRefundAmount] = useState('');
  const [isTransactionCompleted, setIsTransactionCompleted] = useState(false);
  const min = 1;

  const [isScaled, setIsScaled] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsScaled((prevIsScaled) => !prevIsScaled); // Toggle the scale state
    }, 1000); // 1000ms (1 second) interval
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Function to make the API request
  const checkForNewMessage = async () => {
    if (staffData?.chatAccess || !staff) {
      console.log('Checking for new message');
      try {
        const response = await axios.get(`/${staff ? 'staff' : 'business'}/hasNewMsg`); // Replace with your actual API URL
        if (response) {
          const result = response.data.result; // Assuming the API returns JSON with 'newMessage'
          console.log("🚀 ~ file: header.js:154 ~ checkForNewMessage ~ data:", result)
          setNewMessage(result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      console.log('No Chat Access Available')
    }
  };

  useEffect(() => {
    restoreTokenFromStorage();
    checkForNewMessage(); // Initial API call
    const intervalId = setInterval(checkForNewMessage, 5 * 60 * 1000); // 5 minutes

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // **************************************************************************************
  // Set Staff Data
  const staffKey = localStorage.getItem('userData');
  const staffData = JSON.parse(staffKey);
  const isStaff = localStorage.getItem('isStaff');
  const handleOpenStepper = () => {
    setOpenSell(true);
  };
  const [activeStep, setActiveStep] = useState(0);

  // State for saving the current rt details in this state
  const [rt, setRt] = useState({
    customerId: '',
    transactionId: '',
  });

  const [otpError, setOtpError] = useState(null); // State variable for OTP error
  const [confirmation, setConfirmation] = useState(false);
  const [loader, setLoader] = useState(false);

  const [customerId, setCustomerId] = useState('');
  const [billingDetails, setBillingDetails] = useState({
    redeemGCAmount: '',
    redeemWalletAmount: '',
    billingAmount: ''
  });

  const [otp, setOtp] = useState('');
  const [showFields, setShowFields] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [error, setError] = useState({
    msg: '',
    err: false
  });
  const [rewardTransaction, setRewardTransaction] = useState({
    msg: '',
    error: false
  });

  const [redeem, setRedeem] = useState({
    gcMax: false,
    gcErr: false,
    walletMax: false,
    walletErr: false,
  });

  // For Stores
  const [stores, setStores] = useState([]);
  const [storeId, setStoreId] = useState();

  const [timer, setTimer] = useState(0); // 5 minutes in seconds (5 * 60)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [isResendOtpLoading, setIsResendOtpLoading] = useState(false);

  let timerInterval;
  const startTimer = () => {
    setIsButtonDisabled(true);
    timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerInterval);
          setIsButtonDisabled(false);
        }
        return prevTimer - 1;
      });
    }, 1000); // Update the timer every 1 second
  };


  const handleResendClick = async () => {
    // You can implement your logic for resending OTP here.
    // setTimer(300); // Reset the timer to 5 minutes
    // clearInterval(timerInterval); // Clear the previous timer interval
    // startTimer(); // Start the timer again
    setIsResendOtpLoading(true);
    restoreTokenFromStorage();

    // Call the API of send OTP
    try {
      const response = await axios.post(
        isStaff === "true"
          ? endpoints.staff.rewardCustomer.resendOtp
          : endpoints.business.rewardCustomer.resendOtp,
        { rewardTransactionId: rt.transactionId },
      );

      if (response) {
        setOtpError('');
        enqueueSnackbar('OTP resent successfully.', { variant: "success" });
        setIsResendOtpLoading(false);
      };
    } catch (verifyOtpErr) {
      enqueueSnackbar(`${verifyOtpErr.msg || verifyOtpErr.error}`, { variant: "error" });
      setOtpError(`${verifyOtpErr.error}`);
      setIsResendOtpLoading(false);
    }
  };

  // Fetch data for staffs
  const fetchStoreData = async () => {
    try {
      // fetchAPI And save the
      axios.post(endpoints.business.store.list).then(({ data }) => {
        setStores(data.totalItems);
      }).catch(err => console.log(err));
    } catch (storeError) {
      console.error(storeError);
    }
  };


  const addOrUpdateRewardTransaction = async () => {

    try {
      const data = {
        customerId: customerData?.memberId,
        billingAmount: billingDetails.billingAmount,
        gc: billingDetails.redeemGCAmount,
        wallet: billingDetails.redeemWalletAmount,
        rtId: rt.transactionId,
        storeId: isStaff === 'true' ? JSON.parse(localStorage.getItem('storeInfo')).id.toString() : storeId.id
      };

      const response = await axios.post(isStaff === "true" ? endpoints.staff.rewardCustomer.add : endpoints.business.rewardCustomer.add, data);
      if (response) {
        console.log("response", response);
        setRt((prev) => ({ ...prev, customerId: response.data.data.customerId, transactionId: response.data.data.rtId }));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    } catch (rewardErr_1) {
      enqueueSnackbar(`${rewardErr_1.msg || rewardErr_1.error}`, { variant: "error" });
      console.log(rewardErr_1);
    }
  };

  const verifyOtp = async () => {
    try {
      const response = await axios.post(isStaff === "true" ? endpoints.staff.rewardCustomer.verifyOtp : endpoints.business.rewardCustomer.verifyOtp, { otp, rewardTransactionId: rt.transactionId });
      if (response) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setOtpError('');
      };
    } catch (verifyOtpErr) {
      enqueueSnackbar(`${verifyOtpErr.msg || verifyOtpErr.error}`, { variant: "error" });
      setOtpError(`${verifyOtpErr.msg || verifyOtpErr.error}`);
    }
  };

  const rewardSubscriptionToCustomer = async () => {
    try {
      setLoader(true);
      const data = {
        customerId: customerData?.memberId,
        billingAmount: billingDetails?.billingAmount,
        gc: billingDetails?.redeemGCAmount,
        wallet: billingDetails?.redeemWalletAmount,
        rtId: rt.transactionId,
        storeId: isStaff === 'true' ? JSON.parse(localStorage.getItem('storeInfo')).id.toString() : storeId.id
      };

      const response = await axios.post(isStaff === "true" ? endpoints.staff.rewardCustomer.reward : endpoints.business.rewardCustomer.reward, data);

      if (response.data) {
        setActiveStep((prevActive) => prevActive + 1);
        setTimeout(() => {
          setLoader(false)
        }, 2000)
      };

    } catch (e) {
      console.error("e called")
      enqueueSnackbar(`${e.error}`, { variant: "error" });
      setRewardTransaction((prev) => ({ ...prev, error: true, msg: e.error }));
      setActiveStep((prevActive) => prevActive + 1);
    }
  };

  const deleteExistingRewardTransaction = async () => {
    try {
      setLoader(true);
      const data = {
        rtId: rt.transactionId,
      };
      const response = await axios.post(isStaff === "true" ? endpoints.staff.rewardCustomer.delete : endpoints.business.rewardCustomer.delete, data);

      if (response.data) {
        setShowFields(false);
        setConfirmation(false);
        setBillingDetails((prev) => ({
          ...prev,
          redeemGCAmount: '',
          redeemWalletAmount: '',
          billingAmount: ''
        }));
        setOtp('');
        setOtpError(null);
        setCustomerData({});
        setRt((prev) => ({ ...prev, transactionId: '', customerId: '' }));
        setActiveStep(0);
      };

    } catch (e) {
      enqueueSnackbar(`${e.error}`, { variant: "error" });
      console.log(e);
    }
  };

  const handleNext = () => {
    setNextButtonLoading(true);
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setNextButtonLoading(false);
      return
    }

    if (activeStep === 1) {
      if (billingDetails?.redeemGCAmount?.length === 0 && billingDetails?.redeemWalletAmount?.length === 0) {
        setActiveStep(3);
        setNextButtonLoading(false);
        return;
      }
      addOrUpdateRewardTransaction();
      setOtpError('');
      setNextButtonLoading(false);
      setTimer(300);
      clearInterval(timerInterval);
      startTimer();
    }

    if (activeStep === 2) {
      verifyOtp();
      setNextButtonLoading(false);
    }

    if (activeStep === 3) {
      setNextButtonLoading(false);
      rewardSubscriptionToCustomer();
    }
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setBackButtonLoading(true);
    if (activeStep === 3 && (billingDetails?.redeemGCAmount?.length === 0 || billingDetails?.redeemWalletAmount?.length === 0)) {
      setActiveStep(1);
      console.log('activeStep', activeStep)
      setBackButtonLoading(false);
      return
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setBackButtonLoading(false);
  };

  // For step 3
  const handleCustomerIdKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the Enter key
      fetchCustomerData();
    }
  };

  const handleCustomerIdKeyDownForRefund = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default behavior of the Enter key
      fetchRefundCustomerData();
    }
  };

  // API Call for Customer Data Fetch
  const fetchCustomerData = async () => {
    try {
      const data = { operation: "reward" };
      let searchType;

      // Identify the search type based on the input value
      if (customerId.includes('@')) {
        data.query = customerId;
        searchType = true;
      } else {
        data.query = customerId;
        searchType = false;
      };


      const apiEndpoint = localStorage.getItem('isStaff') === 'true' ? endpoints.staff.customer.search : endpoints.business.customer.search;
      // first update the device
      const response = await axios.post(apiEndpoint, data);
      console.log("🚀 ~ file: header.js:376 ~ fetchCustomerData ~ response:", response)

      if (Object.keys(response.data).length === 0) {
        const errorMsg = !searchType ? 'Invalid username, please enter a valid one!' : 'Invalid email, please enter a valid one!';
        setError((pre) => ({ ...pre, msg: errorMsg, err: true }));
        setShowFields(false);
        return;
      }
      // Edge cases 
      setCustomerData(response.data);
      setShowFields(true);
      setCustomerId('');
    } catch (customerError) {

      console.error(customerError);
      setShowFields(false);
      setError((pre) => ({ ...pre, msg: customerError.error || customerError.msg }));
      enqueueSnackbar(`${customerError.error}`, { variant: 'error' });
    }
  };

  const fetchRefundCustomerData = async () => {
    try {
      const data = {};
      let searchType;

      // Refund the customer
      if (refundCustomerId.length > 0) {
        if (refundCustomerId.includes('@')) {
          data.query = refundCustomerId;
          searchType = true;
        } else {
          data.query = refundCustomerId;
          searchType = false;
        };
      };

      const apiEndpoint = localStorage.getItem('isStaff') === 'true' ? endpoints.staff.customer.search : endpoints.business.customer.search;
      // first update the device
      const response = await axios.post(apiEndpoint, data);

      // Edge cases 
      if (Object.keys(response.data).length === 0) {
        const errorMsg = !searchType ? 'Invalid username, please enter a valid one!' : 'Invalid email, please enter a valid one!';
        setRefundErr((pre) => ({ ...pre, msg: errorMsg, err: true }));
        setShowRefundFields(false);
        return;
      };
      setRefundCustomerData(response.data);
      setShowRefundFields(true);
      setRefundCustomerId('');
    } catch (customerError) {
      setShowRefundFields(false);
      setRefundErr((pre) => ({ ...pre, msg: customerError.error || customerError.msg }));
      enqueueSnackbar(`${customerError.error}`, { variant: 'error' });
    }
  };

  const handleModalClose = () => {
    const confirmClose = window.confirm(`Are you sure you want to close the Transaction ${rt.transactionId}?`);

    if (confirmClose) {
      // User confirmed, close the modal
      setRewardModal(false);
      if (rt.transactionId) {
        deleteExistingRewardTransaction();
      }
      setShowFields(false);
      setConfirmation(false);
      setBillingDetails((prev) => ({
        ...prev,
        redeemGCAmount: '',
        redeemWalletAmount: '',
        billingAmount: ''
      }));
      setOtp('');
      setOtpError(null);
      setCustomerData({});
      setRt((prev) => ({ ...prev, transactionId: '', customerId: '' }));
      setActiveStep(0);
    }
    // If the user cancels, do nothing and keep the modal open
  };

  const handleRefund = async () => {
    try {
      const data = {};

      data.refundAmount = refundAmount;
      data.customerId = refundCustomerData?.memberId;
      data.storeId = isStaff === 'true' ? JSON.parse(localStorage.getItem('storeInfo')).id.toString() : storeId.id;

      const apiEndpoint = localStorage.getItem('isStaff') === 'true' ? endpoints.staff.customer.refund : endpoints.business.customer.refund;
      // first update the device
      const response = await axios.post(apiEndpoint, data);
      console.log("response", response);
      if (response.data) {
        setIsTransactionCompleted(true);
        enqueueSnackbar(response.data.msg, { variant: 'success' })
      }

    } catch (refundError) {
      setIsRefundLoading(false);
      enqueueSnackbar(`${refundError.error}`, { variant: 'error' });
    }
  };

  const handleCloseTransaction = (req, res) => {
    // User confirmed, close the modal
    setRewardModal(false);
    setShowFields(false);
    setConfirmation(false);
    setBillingDetails((prev) => ({
      ...prev,
      redeemGCAmount: '',
      redeemWalletAmount: '',
      billingAmount: ''
    }));
    setOtp('');
    setOtpError(null);
    setCustomerData({});
    setRt((prev) => ({ ...prev, transactionId: '', customerId: '' }));
    setActiveStep(0);
    setRewardTransaction((prev) => ({ ...prev, msg: '', error: false }));
  };

  const isNextCondition = [
    { index: 0, condition: !showFields || (isStaff === 'true' ? null : !storeId) },
    { index: 1, condition: (billingDetails.billingAmount.length === 0) || (redeem.walletErr) || (redeem.gcErr) },
    { index: 2, condition: otp.length !== 4 },
    { index: 3, condition: !confirmation },
    { index: 4, condition: false },
    { index: 5, condition: false },
    { index: 6, condition: false },
  ];
  const disableConditon = isNextCondition[activeStep].condition;
  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {/* Search Customer Flow */}
      <Grid container justifyContent='center' spacing={2} >
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} >
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button color='primary' variant='outlined' onClick={() => {
              setRewardModal(true);
              const valueForStore = JSON.parse(localStorage.getItem("ps"));

              // Store Only the first time 
              if (valueForStore && storeId === undefined) {
                setStoreId(valueForStore);
              };

              restoreTokenFromStorage();
              fetchStoreData();
            }}>
              Reward Customer
            </Button>

            {
              !staff &&
              <Button color='primary' variant='outlined' onClick={handleOpenStepper}>
                Sell Giftcards
              </Button>
            }

            <Button color='primary' variant='outlined' onClick={() => {
              setRefundModal(true);
              const valueForStore = JSON.parse(localStorage.getItem("ps"));

              // Store Only the first time 
              if (valueForStore && storeId === undefined) {
                setStoreId(valueForStore);
              };

              restoreTokenFromStorage();
              fetchStoreData();
            }}>
              Issue Refund
            </Button>
            <div>
              <Modal
                open={openSell}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <SellGiftcardsStepper onClose={handleClose} />
              </Modal>
            </div>
          </Box>
        </Grid>
      </Grid>

      {/* OLD FLOW */}
      <div>
        <Modal open={rewardModal}>
          <Box sx={responsiveStyle}>
            <IconButton
              edge="end"
              color="error"
              onClick={handleModalClose}
              aria-label="close"
              style={{ position: 'absolute', top: '10px', right: '15px' }}
            >
              <ClearIcon />
            </IconButton>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>

              {activeStep === 0 && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                  <Grid container spacing={2}>

                    <Grid item xs={12} md={isStaff === 'true' ? 12 : 6} lg={isStaff === 'true' ? 12 : 6} sx={{ mb: 2 }}>
                      <TextField
                        label="Username or Customer ID"
                        placeholder="Enter username or customer ID"
                        value={customerId}
                        onChange={(event) => {
                          setCustomerId(event.target.value);
                          setError((pre) => ({ ...pre, msg: '', err: false }));
                        }}
                        onKeyDown={handleCustomerIdKeyDown}
                        autoFocus
                        fullWidth
                        error={error.err}
                        helperText={error.msg.length ? error.msg : null}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* <IconButton onClick={() => {
                                console.log("scan")
                              }} edge="end">
                                <QrCodeScannerIcon />
                              </IconButton> */}

                              <IconButton onClick={(event) => {
                                if (customerId.length === 0) {
                                  setError((prev) => ({ ...prev, err: true, msg: "Please Enter Customer ID or Email.." }))
                                }
                                event.preventDefault();
                                fetchCustomerData();
                              }} edge="end" >
                                <SearchIcon />
                              </IconButton>

                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography variant="caption" display="block" gutterBottom>Please enter username or customer ID and press enter to proceed</Typography>
                      {/* <Typography variant="caption" display="block" gutterBottom>use this: @sai_7 || C1687952202491 </Typography> */}
                    </Grid>

                    {/* For Store */}
                    {
                      isStaff !== 'true'
                      &&
                      <Grid item xs={12} md={6} lg={6} sx={{ mb: 2 }}>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <InputLabel id="demo-simple-select-label">Store</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(stores.find(option => option.name === storeId?.name) ?? '')}
                            label="Store"
                            onChange={(event) => {
                              const selectedStore = event.target.value;
                              console.log(selectedStore);
                              setStoreId(selectedStore);
                              localStorage.setItem("ps", JSON.stringify(selectedStore));
                            }}
                            placeholder='Please select store first to proceed..'
                          >
                            {
                              stores.map((storeOption, index) => (
                                <MenuItem key={index} value={storeOption}>
                                  {storeOption.name}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                    }


                    {showFields && (
                      <>

                        <Grid item xs={12} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <Typography variant="body2" display="block" gutterBottom>
                            Customer Name:
                          </Typography>
                          <Typography variant="body1" gutterBottom style={{ marginLeft: "10px" }}>
                            {customerData?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <Typography variant="body2" gutterBottom>
                            Email:
                          </Typography>
                          <Typography variant="body1" style={{ marginLeft: "10px" }} gutterBottom>
                            {customerData?.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <Typography variant="body2" gutterBottom>
                            Customer ID:
                          </Typography>
                          <Typography variant="body1" style={{ marginLeft: "10px" }} gutterBottom>
                            {customerData?.memberId}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>

                </div>
              )}
              {activeStep === 1 && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Grid item xs={6} md={6} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography variant="overline" gutterBottom>Billing Amount :</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} >
                      <TextField
                        label="Billing Amount"
                        value={billingDetails.billingAmount}
                        type="number"
                        onChange={(event) => setBillingDetails((prev) => ({ ...prev, billingAmount: event.target.value }))}
                        fullWidth

                        autoFocus
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                      <Typography variant="overline" gutterBottom>
                        Remaining balance on the Wallet:
                      </Typography>
                      <Typography variant="button" style={{ marginLeft: "10px" }} color='primary' gutterBottom>
                        {customerData?.walletBalance ? `$${customerData?.walletBalance}` : `$${0}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <TextField
                        type="number"
                        label="Redeem Wallet Amount"
                        value={billingDetails.redeemWalletAmount}
                        onChange={(event) => {
                          let value = event.target.value.trim(); // Remove leading/trailing whitespace
                          console.log("🚀 ~ file: header.js:749 ~ Header ~ value:", value)
                          if (value === '' || value.includes('-')) {
                            console.log('called')
                            value = ''; // Set the value to an empty string
                          } else {
                            value = parseInt(value, 10); // Parse the integer
                          };

                          const maximumWalletRedeemAmount = Math.min((billingDetails.billingAmount - (billingDetails?.redeemGCAmount ?? 0)), customerData?.walletBalance)
                          if (value > maximumWalletRedeemAmount) {
                            value = maximumWalletRedeemAmount;
                          } else if (value === 0) {
                            value = 1;
                          }
                          console.log("value", value);
                          setBillingDetails((prev) => ({ ...prev, redeemWalletAmount: value }))
                        }
                        }
                        fullWidth
                        error={redeem.walletErr}
                        helperText={redeem.walletErr ? 'Please enter an amount less than or equal to the billing amount' : null}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={!billingDetails.billingAmount || customerData.walletBalance <= 0}
                      />
                    </Grid>

                    <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                      <Typography variant="overline" gutterBottom>
                        Remaining balance on the Giftcard:
                      </Typography>
                      <Typography variant="button" style={{ marginLeft: "10px" }} color='primary' gutterBottom>
                        {customerData?.gcBalance ? `$${customerData?.gcBalance}` : `$${0}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <TextField
                        type="number"
                        label="Redeem Gift Card Amount"
                        value={billingDetails.redeemGCAmount}
                        onChange={(event) => {
                          let value = event.target.value.trim(); // Remove leading/trailing whitespace
                          if (value === '') {
                            value = ''; // Set the value to an empty string
                          } else {
                            value = parseInt(value, 10); // Parse the integer
                          };
                          const maximumGCRedeemAmount = Math.min((billingDetails.billingAmount - (billingDetails?.redeemWalletAmount ?? 0)), customerData?.gcBalance)
                          if (value > maximumGCRedeemAmount) {
                            value = maximumGCRedeemAmount;
                          } else if (value === 0) {
                            value = 1;
                          };

                          setBillingDetails((prev) => ({ ...prev, redeemGCAmount: value }))
                        }}
                        fullWidth
                        error={redeem.gcErr}
                        helperText={redeem.gcErr ? 'Please enter an amount less than or equal to the billing amount' : null}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        disabled={!billingDetails.billingAmount || customerData.redeemGCAmount <= 0}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}

              {activeStep === 2 && (
                <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px', justifyContent: "center" }}>
                  <Grid item xs={12} md={12} display='flex' justifyContent='end'>
                    Transaction ID: {rt.transactionId}
                  </Grid>

                  <Grid item xs={12} md={12}>
                    Enter OTP from customer
                    <MuiOtpInput name="otp"
                      autoFocus
                      gap={2}
                      length={4}
                      value={otp ?? ''}
                      onChange={(value) => {
                        setOtp(value);
                      }}
                      TextFieldsProps={{ placeholder: '-' }}
                    />
                    {!otpError ?
                      (
                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
                          <span style={{ color: "green", fontSize: "smaller" }}>
                            OTP sent successfully...
                          </span>
                          <div>
                            <LoadingButton loading={isResendOtpLoading} variant='outlined' size="small" className='mt-2'
                              // disabled={isButtonDisabled}
                              onClick={handleResendClick}>
                              {/* {isButtonDisabled ? `Resend OTP? ( ${Math.floor(timer / 60)}:${(timer % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })})` : 'Resend OTP?'} */}
                              Resend OTP?
                            </LoadingButton>
                          </div>
                        </div>
                      ) :
                      (
                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-between" }}>
                          <span style={{ color: "red", fontSize: "smaller" }}>
                            {otpError}
                          </span>
                          <div>
                            <LoadingButton loading={isResendOtpLoading} variant='outlined' size="small" className='mt-2'
                              // disabled={isButtonDisabled}
                              onClick={handleResendClick}>
                              {/* {isButtonDisabled ? `Resend OTP? ( ${Math.floor(timer / 60)}:${(timer % 60).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })})` : 'Resend OTP?'} */}
                              Resend OTP?
                            </LoadingButton>
                          </div>
                        </div>

                      )
                    }
                  </Grid>
                </Grid>
              )}

              {activeStep === 3 && (
                <Grid container spacing={2} style={{ marginTop: '10px', marginBottom: '10px', justifyContent: "center" }}>
                  <Grid item xs={12} md={12} display='flex' justifyContent='center'>
                    BUSINESS APPROVAL
                  </Grid>

                  {/* <Grid item xs={12} md={12}>
                    <Typography variant="overline" gutterBottom>
                      Summary:
                    </Typography>
                  </Grid> */}

                  <Grid item xs={12} md={12} lg={12} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                    <Typography variant="overline" gutterBottom>
                      Remaining balance to be collected:
                    </Typography>
                    <Typography variant="button" style={{ marginLeft: "10px" }} color='primary' gutterBottom>
                      ${billingDetails.billingAmount - (billingDetails.redeemGCAmount.length === '' ? 0 : billingDetails.redeemGCAmount) - (billingDetails.redeemWalletAmount.length === '' ? 0 : billingDetails.redeemWalletAmount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={<Checkbox
                        color="primary"
                        checked={confirmation}
                        onChange={(e) => setConfirmation(e.target.checked)}
                      />}
                      label="I confirm that the remaining payment has been collected before successfully processed before proceeding."
                    />
                  </Grid>
                </Grid>
              )}

              {activeStep === 4 && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  {
                    rewardTransaction.error ?
                      <>
                        <Typography variant="h6" gutterBottom color='error'>
                          Transaction Error!
                        </Typography>
                        <Typography variant='body2' gutterBottom color='error'>
                          {rewardTransaction?.msg}
                        </Typography>
                        <Typography variant="body2" style={{ marginTop: '10px' }}>
                          An error has occurred, please note the transaction ID for future references ID: <Typography variant="overline" gutterBottom>{rt.transactionId}</Typography>
                        </Typography>
                      </> :
                      <>
                        {
                          !loader
                            ?
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <Typography variant="h6" color='primary' gutterBottom>
                                Transaction Complete!
                              </Typography>
                            </div>
                            :
                            <>
                              <Typography variant="h6" gutterBottom>
                                Processing Transaction...
                              </Typography>
                              <CircularProgress color='primary' style={{ marginTop: '20px' }} />
                              <Typography variant="body2" style={{ marginTop: '10px' }}>
                                Please wait while we proceed your transaction.
                              </Typography>
                            </>
                        }
                      </>
                  }
                </div>
              )}

              {
                rewardTransaction.error
                  ?
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Button onClick={handleCloseTransaction} variant='outlined' color='error' >
                      Close
                    </Button>
                  </Box>
                  :
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>

                    <LoadingButton onClick={handleBack} loading={backButtonLoading} variant='outlined' disabled={activeStep === 0 || (activeStep === 3 && otpError?.length === 0) || (activeStep === 4 && confirmation)}>
                      Back
                    </LoadingButton>
                    {activeStep === steps.length - 1 ? (
                      <LoadingButton variant="contained" loading={finishButtonLoading} color='primary' onClick={() => {
                        setFinishButtonLoading(true);
                        setRewardModal(false);
                        setShowFields(false);
                        setConfirmation(false);
                        setBillingDetails((prev) => ({
                          ...prev,
                          redeemGCAmount: '',
                          redeemWalletAmount: '',
                          billingAmount: ''
                        }));
                        setOtp('');
                        setOtpError(null);
                        setCustomerData({});
                        setRt((prev) => ({ ...prev, transactionId: '', customerId: '' }));
                        setActiveStep(0);
                        setFinishButtonLoading(false);
                      }}>
                        Finish
                      </LoadingButton>
                    ) : (
                      <LoadingButton variant="contained" color='primary' loading={nextButtonLoading} onClick={handleNext} disabled={disableConditon}>
                        Next
                      </LoadingButton>
                    )}
                  </Box>
              }



            </div>
          </Box>
        </Modal>
      </div>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover /> */}

        {/* Only admin able to view all this */}


        {(staffData?.chatAccess === true || !staff)
          && <IconButton
            component={m.button}
            whileTap="tap"
            whileHover="hover"
            variants={varHover(1.05)}
            color={popover.open ? 'inherit' : 'default'}
            // onClick={popover.onOpen}
            onClick={handleChatPageClick}
            sx={{
              ...(popover.open && {
                bgcolor: (thme) => thme.palette.action.selected,
              }),
            }}
          >
            <ChatIcon />

            {
              newMessage ?
                <div
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                  }}
                />
                :
                null
            }

          </IconButton>
        }


        {
          staff ?
            null
            :
            (
              <>
                {/* <NotificationsPopover /> */}

                <ContactsPopover />

                <SettingsButton />
              </>
            )
        }


        <AccountPopover />
      </Stack>
    </>
  );

  return (<>
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}

        {/* Refund Modal Content */}
        <Modal open={refundModal}
        // onClose={() => setRefundModal(false)}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 900,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
            borderRadius: "10px",
          }}>




            <Grid container xs={12} md={12} lg={12} spacing={2}>

              <Grid item xs={11} md={11} lg={11} justifyContent='center' alignItems='center' fullWidth>
                <Typography variant="h4" align="center" fontWeight="bold">
                  Issue Refund
                </Typography>
              </Grid>

              {
                !isTransactionCompleted
                  ?
                  (
                    <Grid item xs={1} md={1} lg={1} display='flex' justifyContent='flex-end' alignItems='center'>
                      <Button color='error' variant='outlined' onClick={() => {
                        setRefundModal(false);
                        setIsTransactionCompleted(false);
                        setShowRefundFields(false);
                        setRefundAmount('');
                        setRefundConfirmation(false);
                        setRefundCustomerData([]);
                      }}>
                        <ClearIcon />
                      </Button>
                    </Grid>
                  ) :
                  null
              }


              {
                isTransactionCompleted ?
                  (
                    <Grid container xs={12} md={12} lg={12} spacing={2}>
                      <Grid item xs={12} md={12} lg={12} display='flex' justifyContent='center' alignItems='center' flexDirection='column' fullWidth>
                        <CheckCircleIcon style={{ color: 'green', fontSize: 100, transition: 'transform 0.5s ease-in-out', transform: isScaled ? 'scale(1.2)' : 'scale(1)', }} /> {/* Green tick icon */}
                        <Typography variant="h4" align="center" fontWeight="bold">Transaction Completed!</Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} display='flex' justifyContent='center' alignItems='center'>
                        <Button color='primary' variant='contained' onClick={() => {
                          setRefundModal(false);
                          setIsTransactionCompleted(false);
                          setShowRefundFields(false);
                          setRefundAmount('');
                          setRefundConfirmation(false);
                          setRefundCustomerData([]);
                        }} >Done</Button>
                      </Grid>

                    </Grid>
                  )
                  :

                  <>
                    <Grid item xs={12} md={isStaff === 'true' ? 12 : 6} lg={isStaff === 'true' ? 12 : 6}>
                      <TextField
                        autoFocus
                        label='Customer username or ID'
                        value={refundCustomerId}
                        onChange={(e) => {
                          setRefundCustomerId(e.target.value);
                          setRefundErr((pre) => ({ ...pre, msg: '', err: false }));
                        }}
                        color='primary'
                        onKeyDown={handleCustomerIdKeyDownForRefund}
                        placeholder='Customer Username or ID'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={(event) => {
                                if (refundCustomerId.length === 0) {
                                  setRefundErr((prev) => ({ ...prev, err: true, msg: "Please enter customer username or Email.." }))
                                  return;
                                }
                                event.preventDefault();
                                fetchRefundCustomerData();
                              }} edge="end" >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={refundErr.err}
                        helperText={refundErr.msg.length ? refundErr.msg : null}
                        fullWidth />
                      <Typography variant="caption" display="block" gutterBottom>Please enter username or customer ID and press enter to proceed</Typography>
                      {/* <Typography variant="caption" display="block" gutterBottom>use this: @sai_7 || C1687952202491 </Typography> */}
                    </Grid>

                    {/* For Store */}
                    {
                      isStaff !== 'true'
                      &&
                      <Grid item xs={12} md={6} lg={6} sx={{ mb: 2 }}>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <InputLabel id="demo-simple-select-label">Store</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={(stores.find(option => option.name === storeId?.name) ?? '')}
                            label="Store"
                            onChange={(event) => {
                              const selectedStore = event.target.value;
                              console.log(selectedStore);
                              setStoreId(selectedStore);
                              localStorage.setItem("ps", JSON.stringify(selectedStore));
                            }}
                            placeholder='Please select store first to proceed..'
                          >
                            {
                              stores.map((storeOption, index) => (
                                <MenuItem key={index} value={storeOption}>
                                  {storeOption.name}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                    }


                    {showRefundFields && (
                      <>
                        <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <Typography variant="body2" display="block" gutterBottom>
                            Customer Name:
                          </Typography>
                          <Typography variant="body1" gutterBottom style={{ marginLeft: "10px" }}>
                            {refundCustomerData?.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <Typography variant="body2" gutterBottom>
                            Email:
                          </Typography>
                          <Typography variant="body1" style={{ marginLeft: "10px" }} gutterBottom>
                            {refundCustomerData?.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                          <Typography variant="body2" gutterBottom>
                            Customer ID:
                          </Typography>
                          <Typography variant="body1" style={{ marginLeft: "10px" }} gutterBottom>
                            {refundCustomerData?.memberId}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} md={12} lg={12} >
                      <TextField
                        fullWidth
                        label="Refund Amount"
                        value={refundAmount}
                        onChange={(e) => {
                          let value = parseInt(e.target.value, 10);
                          if (value < min) value = min;
                          setRefundAmount(value)
                        }}
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={refundConfirmation}
                            onChange={(e) => setRefundConfirmation(e.target.checked)}
                          />
                        }
                        label="I confirm that the I'm making refund before proceeding."
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} display='flex' justifyContent='center' alignItems='center'>
                      <LoadingButton loading={isRefundLoading} loadingPosition="start" startIcon={< CurrencyExchangeIcon />} variant="contained" color="primary" onClick={handleRefund}
                        disabled={!showRefundFields || !refundConfirmation || !refundAmount || (isStaff === 'true' ? null : !storeId)}
                      >Proceed Refund </LoadingButton>
                    </Grid>
                  </>

              }
            </Grid>

          </Box>
        </Modal>
      </Toolbar>
    </AppBar>
  </>


  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
