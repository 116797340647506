import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { jwtDecode } from 'src/auth/context/jwt/utils';
import { decryptToken } from 'src/utils/common';


// Search Customer for staff
const SearchCustomerPage = lazy(() => import('src/pages/dashboard/searchCustomer'));

// Staff Page 
const StaffPage = lazy(() => import('src/pages/dashboard/Staff'));

// Stores Page 
const StoresPage = lazy(() => import('src/pages/dashboard/stores'));

// Subscribers Page 
const SubscribersPage = lazy(() => import('src/pages/dashboard/subscribers'));

// Devices Page 
const Devices = lazy(() => import('src/pages/dashboard/devices'));

// Transactions Page 
const TransactionsPage = lazy(() => import('src/pages/dashboard/transactions'));

// Offers Page 
const OffersPage = lazy(() => import('src/pages/dashboard/offers'));

// Notifications Page 
const NotificationPage = lazy(() => import('src/pages/dashboard/notifications'));

// Reward Plan Page 
const RewardPlanPage = lazy(() => import('src/pages/dashboard/rewardPlan'));

// Gift Card Page 
const GiftCardPage = lazy(() => import('src/pages/dashboard/giftCard'));

// Billing Page 
const BillingPage = lazy(() => import('src/pages/dashboard/billing'));

// Gallery Page 
const GalleryPage = lazy(() => import('src/pages/dashboard/gallery'));

// Profile Page 
const ProfilePage = lazy(() => import('src/pages/dashboard/profile'));

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));

const token = localStorage.getItem('token');
let decodedTokenAvailable = '';
let userType = '';


if (!token) {
  // window.location.href = '/';
} else {

  decodedTokenAvailable = decryptToken(token);
  userType = jwtDecode(decodedTokenAvailable).userType
}

function ChatPage2() {
  return (
    <title>Hello World</title>
  );
}
let dashboard = [];

if (userType === 'B') {
  dashboard = [
    {
      path: 'dashboard-o',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: 'staffs', element: <StaffPage /> },
        { path: 'stores', element: <StoresPage /> },
        { path: 'subscribers', element: <SubscribersPage /> },
        { path: 'devices', element: <Devices /> },
        { path: 'offers', element: <OffersPage /> },
        { path: 'transactions', element: <TransactionsPage /> },
        { path: 'rewardplan', element: <RewardPlanPage /> },
        { path: 'giftcards', element: <GiftCardPage /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'notifications', element: <NotificationPage /> },
        { path: 'billing', element: <BillingPage /> },
        { path: 'gallery', element: <GalleryPage /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'chat_s', element: <Navigate to="/404" replace /> },
      ],
    }
  ]
} else {
  dashboard = [
    {
      path: 'dashboard-s',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <SearchCustomerPage />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      // children: [
      //   { path: 'chat_s', element: <Navigate to="/404" replace /> },
      // ],
    },
    {
      path: 'dashboard-s/chat',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <ChatPage />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      // children: [
      //   { path: 'chat_s', element: <Navigate to="/404" replace /> },
      // ],
    },
  ];
}

const dashboardRoutes = dashboard;
// ----------------------------------------------------------------------
// const dashboardRoutes = userType === 'B' ? [
//   {
//     path: 'dashboard-o',
//     element: (
//       <AuthGuard>
//         <DashboardLayout>
//           <Suspense fallback={<LoadingScreen />}>
//             <Outlet />
//           </Suspense>
//         </DashboardLayout>
//       </AuthGuard>
//     ),
//     children: [
//       { element: <IndexPage />, index: true },
//       { path: 'staffs', element: <StaffPage /> },
//       { path: 'stores', element: <StoresPage /> },
//       { path: 'subscribers', element: <SubscribersPage /> },
//       { path: 'devices', element: <Devices /> },
//       { path: 'offers', element: <OffersPage /> },
//       { path: 'transactions', element: <TransactionsPage /> },
//       { path: 'rewardplan', element: <RewardPlanPage /> },
//       { path: 'giftcards', element: <GiftCardPage /> },
//       { path: 'chat', element: <ChatPage /> },
//       { path: 'notifications', element: <NotificationPage /> },
//       { path: 'billing', element: <BillingPage /> },
//       { path: 'gallery', element: <GalleryPage /> },
//       { path: 'profile', element: <ProfilePage /> },
//       { path: 'chat_s', element: <Navigate to="/404" replace /> },
//     ],
//   }
// ] : [
//   {
//     path: 'dashboard-s',
//     element: (
//       <DashboardLayout>
//         <SearchCustomerPage />
//       </DashboardLayout>
//     ),
//     children: [
//       { path: 'chat_s', element: <Navigate to="/404" replace /> },
//     ],
//   },
// ];

export { dashboardRoutes };

