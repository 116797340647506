import { _mock } from 'src/_mock';

// TO GET THE USER FROM THE AUTHCONTEXT, YOU CAN USE

// CHANGE:
// import { useMockedUser } from 'src/hooks/use-mocked-user';
// const { user } = useMockedUser();

// TO:
// import { useAuthContext } from 'src/auth/hooks';
// const { user } = useAuthContext();

// ----------------------------------------------------------------------

export function useMockedUser() {

  const staff = localStorage.getItem('isStaff');
  let user;

  const dummyUser = {
    id: '8864c717-587d-472a-929a-8e5f298024da-0',
    displayName: 'N/A',
    email: 'N/A',
    password: 'demo1234',
    photoURL: _mock.image.avatar(24),
    phoneNumber: '+40 777666555',
    country: 'United States',
    address: '90210 Broadway Blvd',
    state: 'California',
    city: 'San Francisco',
    zipCode: '94116',
    about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
    role: 'staff',
    isPublic: true,
  }
  if (staff === 'true') {

    // Get the user data from the local storage
    const storedUserData = localStorage.getItem('userData');

    if (storedUserData) {
      // Parse the JSON string back to an object
      const { name, email, businessId } = JSON.parse(storedUserData);
      user = {
        id: '8864c717-587d-472a-929a-8e5f298024da-0',
        displayName: name ?? 'N/A',
        businessId,
        email: email ?? 'N/A',
        password: 'demo1234',
        photoURL: _mock.image.avatar(24),
        phoneNumber: '+40 777666555',
        country: 'United States',
        address: '90210 Broadway Blvd',
        state: 'California',
        city: 'San Francisco',
        zipCode: '94116',
        about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
        role: 'staff',
        isPublic: true,
      };
    } else {
      user = dummyUser
    }

  } else {

    // Get the user data from the local storage
    const storedBuisnessData = localStorage.getItem('businessData');

    if (storedBuisnessData) {
      // Parse the JSON string back to an object
      const { name, email, businessId } = JSON.parse(storedBuisnessData);
      user = {
        id: '8864c717-587d-472a-929a-8e5f298024da-0',
        displayName: name ?? 'N/A',
        businessId,
        email: email ?? 'N/A',
        password: 'demo1234',
        photoURL: _mock.image.avatar(24),
        phoneNumber: '+40 777666555',
        country: 'N/A',
        address: '90210 Broadway Blvd',
        state: 'California',
        city: 'San Francisco',
        zipCode: '94116',
        about: 'Praesent turpis. Phasellus viverra nulla ut metus varius laoreet. Phasellus tempus.',
        role: 'owner',
        isPublic: true,
      };
    } else {
      user = dummyUser
    }
  }


  return { user };
}
